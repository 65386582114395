// extracted by mini-css-extract-plugin
export var aArrow1 = "Kb";
export var aArrow2 = "Lb";
export var aEmpty = "Ab";
export var aLeft = "Pb";
export var aRight = "Rb";
export var aTagAnnounce = "Hb";
export var aTagClose = "Gb";
export var aTagEntry = "Eb";
export var aTagFree = "Jb";
export var aTagLottery = "Ib";
export var aTagPayment = "Fb";
export var aText = "Mb";
export var aTitle = "Bb";
export var aWrap = "Ob";
export var animationMarquee = "Tb";
export var infinityScrollLeft = "Qb";
export var infinityScrollRight = "Sb";
export var pItemBatch = "Cb";
export var pItemBatchAbs = "Db";
export var pSlideItemList = "Nb";